<template>
    <div class="chars-page">
        <el-card class="basic-card">
            <div slot="header" class="flex flex-justify-between flex-align-center">
                <div class="fonts-18">
                    <span class="cursor-pointer" @click="$router.go(-1)">
                        <svg-icon name="ib-arrow-left"></svg-icon>
                    </span>
                    <span class="text-weight-500">我的流量</span>
                </div>
                <el-button plain round size="small" icon="el-icon-plus" @click="showCharDialog('c10')">字符包</el-button>
            </div>

            <el-table :data="list" style="width: 100%;">
              <!-- <el-table-column type="selection" width="45"></el-table-column> -->
              <!-- <el-table-column label="名称" prop="name" width="214">
                <template slot-scope="scope">{{scope.row.name || '-'}}</template>
              </el-table-column> -->
              <el-table-column label="生效时间" prop="createTime" width="260"></el-table-column>
              <el-table-column label="到期时间" prop="expirationTime" width="260"></el-table-column>
              <el-table-column label="余量" prop="paymentType">
                <template slot-scope="scope">
                    <span class="fonts-12">剩{{scope.row.remainTraffic}}字符 / 总{{scope.row.totalTraffic}}字符</span>
                    <el-progress :percentage="(scope.row.remainTraffic/scope.row.totalTraffic)*100" :show-text="false" :stroke-width="3" color="#00D959"></el-progress>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination-content margin-t-15" style="margin-bottom: 0;">
                <el-pagination background :total="total" :current-page="pageNumber" :page-size="pageSize" @current-change="handleCurrentChange" layout="total, prev, pager, next"></el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
import { userApi } from '@/utils/api';
export default {
    data(){
        return {
            list: [],
            pageNumber: 1,
            pageSize: 10,
            total: 0,
        }
    },
    methods: {
        initPage(){
            let url = `${userApi.queryTrafficList}?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            })
        },
        handleCurrentChange(val){
            this.pageNumber = val;
            this.initPage();
        },
        showCharDialog(type){
            this.$store.dispatch('pay/setCharDialog',{show:true, type});
        }
    },
    mounted(){
        this.initPage();
    },
}
</script>
<style lang="scss" scoped>
.chars-page{
    .basic-card{
        box-shadow: initial;
    }
    ::v-deep .el-card__header{
        padding: 9px 20px;
    }
}
</style>
